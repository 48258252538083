import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import logo from "../../assets/css/images/foliologo.png"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import picture from "../../images/custom-applications-large.png"

export default () => (
  <Layout isHome={false} pageId="folio_single_item" logo={logo}>
    <SEO title="Custom Applications" />
    <div id="content">
      <div id="about_project">
        <h2 className="folio-title">Custom Applications</h2>
        <div className="contact-intro">
          Grow new business with mobile applications, responsive websites, and
          custom software. Our team of business process consultants, project
          managers, designers, and engineers will create software that fully
          realizes the objectives of your project and provides real, measurable
          value.
        </div>

        <h5>Secure, resilient software</h5>
        <p>
          With a focus on intuitive user interaction, we make custom software to
          help you discover efficiencies and reach new markets. Clients in
          financial services, healthcare, payment processing, ecommerce, and
          government have all benefited from our expertise in custom development
          and project stewardship. We believe that well-crafted software is
          long-lasting as well as beautiful.
        </p>
        <h5>Client consultation</h5>
        <p>
          A commitment to transparency and communication allows for a clear view
          of the development process. Our team designs, builds, tests, and
          deploys in close partnership with yours.
          <br />
          <br />
          Our work succeeds by the strength of our project managers and the
          informed governance they bring. To support your objectives, we
          maintain a deep bench of skilled project managers with domain
          expertise covering many sectors — equity and bond markets, retail and
          investment banking, healthcare providers, insurance, and ecommerce.
        </p>
        <ul className="navig">
          <li className="prev">
            <AniLink fade to="/solutions/digital-design" rel="prev">
              Digital Design
            </AniLink>
          </li>
          <li className="next">
            <AniLink fade to="/solutions/ecommerce" rel="next">
              Ecommerce
            </AniLink>
          </li>
        </ul>
      </div>

      <div id="preview">
        <div className="blogimg slide image_1 current">
          <img
            src={picture}
            className="attachment-large-folio-thumb size-large-folio-thumb"
            alt="Custom Applications"
          />
        </div>
        <h5>Beautiful in appearance, obvious in function</h5>
        <p>
          We translate business processes into software, whether that’s
          automating a busy outpatient clinic or developing an end-to-end
          solution for a risk assessment firm. Our secret weapon? We’re great
          listeners.
          <br />
          <br />
          We take time to fully understand your objectives, before we write a
          single line of code.
        </p>
      </div>
    </div>
  </Layout>
)
